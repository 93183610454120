import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL

class AnalyticsService {
  getAnalyticsLogActivitys(params: string) {
    return axios
      .get(`${API_URL}admin/analytics/schools/${JSON.parse(localStorage.getItem('admin-school-id'))}/students/log-activity${params}`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getAnalyticsStickersSent(params: string) {
    return axios
      .get(`${API_URL}admin/analytics/schools/${JSON.parse(localStorage.getItem('admin-school-id'))}/students/stickers-send${params}`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getPaymentLogs(params: string) {
    return axios
      .get(`${API_URL}admin/analytics/schools/${JSON.parse(localStorage.getItem('admin-school-id'))}/payment-logs${params}`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getAnalyticsStudentsFastLearners(params: string) {
    return axios
      .get(`${API_URL}admin/analytics/schools/${JSON.parse(localStorage.getItem('admin-school-id'))}/students/fast-learners${params}`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getAnalyticsStudentsSlowMovers(params: string) {
    return axios
      .get(`${API_URL}admin/analytics/schools/${JSON.parse(localStorage.getItem('admin-school-id'))}/students/slow-movers${params}`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  studentsMovedUpToNextLevel(params: string) {
    return axios
      .get(`${API_URL}admin/analytics/schools/${JSON.parse(localStorage.getItem('admin-school-id'))}/students/moved-up-to-next-level${params}`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  timeSpentInProgram(params: string) {
    return axios
      .get(`${API_URL}admin/analytics/schools/${JSON.parse(localStorage.getItem('admin-school-id'))}/students/time-spent-in-program${params}`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  skillsTracking(params: string) {
    return axios
      .get(`${API_URL}admin/analytics/schools/${JSON.parse(localStorage.getItem('admin-school-id'))}/instructors/skills-tracking${params}`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  studentsMovedToNextLevel(params: string) {
    return axios
      .get(`${API_URL}admin/analytics/schools/${JSON.parse(localStorage.getItem('admin-school-id'))}/instructors/students-moved-to-next-level${params}`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  averageTimeSpentOnLevel(params: string) {
    return axios
      .get(`${API_URL}admin/analytics/schools/${JSON.parse(localStorage.getItem('admin-school-id'))}/instructors/average-time-spent-on-level${params}`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  getBillingActivity(params: string) {
    return axios
      .get(`${API_URL}admin/schools/${JSON.parse(localStorage.getItem('admin-school-id'))}/billing-activity`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
}

export default new AnalyticsService()
