
import { defineComponent } from "vue";
import LoginForm from "@/components/forms/Login.vue";

export default defineComponent({
  name: "Login",
  data() {
    return {};
  },
  components: {
    LoginForm,
  },
  methods: {},
});
