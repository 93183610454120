
import { defineComponent } from 'vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
import { Vue3Lottie } from 'vue3-lottie'
import LoginIcon from '@/assets/svg/login-email-icon.svg?inline'
import PasswordIcon from '@/assets/svg/login-password-icon.svg?inline'
import ShowPasswordIcon from '@/assets/svg/show-password-icon.svg?inline'
import HidePasswordIcon from '@/assets/svg/hide-password-icon.svg?inline'
import { setLocale } from 'yup'

export default defineComponent({
  name: 'Login',
  components: {
    Form,
    Field,
    // ErrorMessage,
    AlertBlock,
    LottieAnimation: Vue3Lottie,
    LoginIcon,
    PasswordIcon,
    ShowPasswordIcon,
    HidePasswordIcon
  },
  data () {
    return {
      errorLogin: null,
      email: '',
      password: '',
      isLoading: false,
      showPassword: false,
      errorLoginMessage: null
    }
  },
  watch: {
    email: {
      deep: true,
      handler () {
        this.errorLogin = null
      },
      immediate: true
    },
    code: {
      deep: true,
      handler () {
        this.errorCode = null
      },
      immediate: true
    }
  },
  computed: {
    loggedIn () {
      return this.$store.state.auth.status.loggedIn
    }
  },
  methods: {
    login (user) {
      user.email = this.email
      user.password = this.password
      this.$store.dispatch('auth/login', user).then(
        (res) => {
          if (res.data.roles[0] === 'super_admin') {
            this.$router.push('/super-admin/school-list')
          } else this.$router.push('/school-choice')
        },
        (error) => {
          this.errorLogin = error.response.data.errors
        }
      )
    },
    togglePasswordVisibility () {
      this.showPassword = !this.showPassword
    }
  }
})
